import React, { FunctionComponent, useEffect } from "react";
import Image from "next/image";
import Head from "next/head";
import styles from "@app/styles/shared/BasicPages.module.scss";

const PageNotFound: FunctionComponent = () => {
  // shallow routing to change the pathName
  useEffect(() => {
    history.pushState(null, null, "/404");
  }, []);

  return (
    <>
      <Head>
        <title>404 - Page Not Found | BI Forms</title>
        <meta name="Page Not Found" content="Page not Found" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className={styles.container}>
        <Image src="lms/404.svg" alt="error" width={1000} height={400} />
        <h1>Page Not Found</h1>
        <p>Sorry, we can&apos;t seem to find the page you are looking for.</p>
        <br />
      </div>
    </>
  );
};

export default PageNotFound;
